.letter-questions {
  .form-control.border-danger {
    z-index: 1;
  }
}

.letter {
  margin: 1rem auto;
  padding: 0.5rem 1rem;
  background-color: #fff;
  box-shadow: 5px 5px 8px rgba(127, 127, 127, 0.3);

  .date {
    font-size: 0.8rem;
    color: #333;
  }

  .message {
  }
}

.blinking-cursor {
  display: inline-block;
  margin-top: -6px;
  margin-left: -6px;
  font-weight: bold;
  font-size: 1.5rem;
  // color: #0077ff;
  color: #ff3300;
  animation: 1s blink step-end infinite;
}

@keyframes blink {
  from,
  to {
    color: transparent;
  }
  50% {
    // color: #0077ff;
    color: #ff3300;
  }
}
