@import '~bootstrap/scss/bootstrap';

html,
body {
  font-family: 'Courier Prime', monospace;
  background-color: #eee;
}

.container {
  max-width: 375px;
}

@import './scss/letter';
@import './scss/entrance';
